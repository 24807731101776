<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container>
            <b-card>
                <spinner v-if="refreshData"></spinner>
                <custom-table
                    v-if="!refreshData"
                    :tableConfig="tableConfig"
                    :fields="fields"
                    :items="items"
                    :totalAmount="totalAmount"
                    :page="page"
                ></custom-table>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import InputFilterEvents from '@/views/client/InputFilterEvents';
import CustomTable from '@/components/shared/table/CustomTable';
import Spinner from '@/components/shared/element/Spinner';
import PageTitle from '@/layouts/components/PageTitle.vue';
import { EVENTS } from '@/constants/client/events';
import { PaymentRepository } from '@/repositories';
import { TABLE } from '@/constants';

export default {
    name: 'ClientInvoicePendingInvoice',
    extends: InputFilterEvents,
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('client.pages.pendingInvoice.title'),
            refreshData: true,
            tableConfig: {
                sortBy: 'ht_amount',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                displayCount: true
            },
            fields: [
                {
                    key: 'ref',
                    label: this.$i18n.t('client.pages.pendingInvoice.labels.ref'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'request_date',
                    label: this.$i18n.t('client.pages.pendingInvoice.labels.requestDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'description',
                    label: this.$i18n.t('client.pages.pendingInvoice.labels.description'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ht_amount',
                    label: this.$i18n.t('client.pages.pendingInvoice.labels.ttcAmount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ttc_amount',
                    label: this.$i18n.t('client.pages.pendingInvoice.labels.debtTtcAmount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'download',
                    label: this.$i18n.t('client.pages.pendingInvoice.labels.download'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            items: [],
            totalAmount: null,
            page: TABLE.PAGE.CLIENT.INVOICE.ID
        };
    },
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_FILTER_CHANGE, () => {
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            PaymentRepository.getPayments(this.getFilters())
                .then(response => {
                    this.refreshData = false;
                    this.items = response.data.data.payment_requests;
                    this.totalAmount = response.data.data.total;
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        }
    }
};
</script>
